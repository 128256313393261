import {
    useEffect,
    useState,
    useRef,
    useCallback,
} from 'react';
import {
    useNavigate,
    useLocation
} from 'react-router-dom';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ShareButton from 'containers/ShareButton';
import MusicPlayerControls from 'containers/MusicPlayerControls';
import ProgressBar from 'components/ProgressBar';
import AudioControl from 'containers/AudioControl';
import MusicDetails from 'pages/MusicDetails';
import MusicDetailDialog from 'components/Modal/MusicDetailDialog';
import LikeButton from 'containers/LikeButton';
import { getSongData } from 'utils/firebase';
import { eventTracker } from 'utils/tracker';
import { abbreviateNumber } from 'utils/formatters';
import {
    updateMetaTag , 
    shuffleIndices 
} from 'utils/index';
import {
    LOADING,
    ERROR,
    SUCCESS,
} from 'constants/index';
import {
    MUSIC_NEXT,
    MUSIC_PREVIOUS,
} from 'constants/trackingEvents';
import { HOME } from 'constants/routes';
import {
    ICurrentMusic, 
    IPlaylists,
    ITrack,
    IPlanObject,
} from 'interfaces';
import LoadingSkeleton from './LoadingSkeleton';

import './MiniPlayer.scss';


interface IMiniPlayerProps {
    className?: string;
    currentMusic: ICurrentMusic | IPlanObject;
    playlists: IPlaylists | IPlanObject;
    isModalOpen?: boolean;
    // eslint-disable-next-line no-unused-vars
    setIsModalOpen?: (arg: boolean) => any;
    // eslint-disable-next-line no-unused-vars
    updatePlayerStatus: (arg: boolean) => any;
    autoPlayAllPlaylists: boolean;
    // eslint-disable-next-line no-unused-vars
    setRepeatPlaylist: (arg: boolean) => any;
    // eslint-disable-next-line no-unused-vars
    setRepeatSingle: (arg: boolean) => any;
    // eslint-disable-next-line no-unused-vars
    setShufflePlaylist: (arg: boolean) => any;
    [key: string]: any;
}

/**
 * MiniPlayer
 * @description 
 * Mini player as default player view. 
 * When user click on it, the slide up modal with full screen music details shows up
 * The audio track is the key element that produce meta data like duration and trigger to play next song once current song ended.
 * The duration data require for calculate the progress bar animation.
 * The progress bar will animate via requestAnimationFrame. Therefore must not trigger react re-render.
 * The player control buttons trigger Action to change currentMusic and flow through to update audio track source to play
 * 
 * TODO: refactor to use Context, useCallback and Memo to pass around the shared states.

 * @param {object} props 
 * @returns {object} React component
 */
function MiniPlayer(props: IMiniPlayerProps) {
    const {
        className = '',
        currentMusic = {} as ICurrentMusic,
        playlists = {} as IPlaylists,
        isModalOpen = false,
        // eslint-disable-next-line no-unused-vars
        setIsModalOpen = (arg: boolean) => {},
        updatePlayerStatus = ()=>{},
        autoPlayAllPlaylists = false,
        setRepeatPlaylist = ()=>{},
        setRepeatSingle = () => {},
        setShufflePlaylist = ()=>{},
    } = props;

    const {
        playlistId = '',
        musicId = '',
        index = 0,
        isPlaying,
        isRepeat = false,
        isRepeatSingle = false,
        isShuffle = false
    } = currentMusic;

    const currentPlaylist = playlists[playlistId];

    // states
    const [srcStatus, setSrcStatus] = useState(LOADING);
    const [duration, setDuration] = useState(0);
    const [currentTrack, setCurrentTrack] = useState({} as ITrack);

    // Refs to keep values without re-render
    const playlistIdsRef: any = useRef();
    const shuffledIndicesRef: any = useRef();
    const audioRef: any = useRef();
    const progressBarRef: any = useRef();
    const progressInputRangeRef: any = useRef();
    const progressTimeRef: any = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=> {
        // convert firebase file to downloadable url
        async function asyncFn() {
            const tracks = currentPlaylist.musics;
            const track = tracks[index];

            try {
                // update og meta tags for sharing
                updateMetaTag({
                    tagName: 'og:title',
                    content: track.musicName,
                });

                updateMetaTag({
                    tagName: 'og:image',
                    content: track.cover,
                });

                const filePath = track.src || '';

                if ((/^gs:\/\/moonlit-watch-404012.appspot.com/).test(filePath)) {
                    const {
                        src,
                        lyrics,
                        cover,
                    } = await getSongData({
                        src: track.src,
                        lyrics: track.lyrics,
                        cover: track.cover,
                        musicId,
                    });

                    track.src = src;
                    track.lyrics = lyrics;
                    track.cover = cover;
                }

                setCurrentTrack(track);
                setSrcStatus(SUCCESS);

                // auto play music -- this have to be trigger by user. eg clicked a song item
                // which change the location
                if (location.state && location.state.playMusic) {
                    if (!currentMusic.isPlaying) {
                        updatePlayerStatus(true);
                        // clear the history state data
                        window.history.replaceState({}, '');
                    }
                }
            
            } catch(err) {
                // still set current track but it can not play
                track.src = '';
                track.lyrics = '';
                setCurrentTrack(track);
                // console.error(err);
                setSrcStatus(ERROR);
            }
        }

        if (currentPlaylist && currentPlaylist.musics) {
            asyncFn();
        }
    }, [currentPlaylist, index]);

    const setPlaylistIds = () => {
        // set playlistIds array once
        if (!playlistIdsRef.current) {
            playlistIdsRef.current = Object.keys(playlists);
        }
    };

    const handleModalOpen = ()=> {
        // change url
        navigate(`/${playlistId}/${musicId}`, { state: { scrollTop: false } });
        setIsModalOpen(true);
    };

    const handleModalClose = (event: object, reason: string ) => {
        // enforce user must click 'close' to close dialog
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        // back to home
        navigate(HOME, { state: { scrollTop: false } });
    };

    const replayCurrentSong = ()=> {
        if (audioRef && audioRef.current) {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        }
    };

    /**
     * support play through muliple playlists
     * play next music
     * if reach end of current playlist then play first music of next playlist
     * if no more next playlist, play 1st music of first playlist
     */
    const handleNext = () => {
        // isRepeatSingle - AudioControl will call onEnd. Rewind time to 0 to play again
        if (isRepeatSingle) {
            replayCurrentSong();
            return;
        }

        setPlaylistIds();
        const playlistIds = playlistIdsRef.current;
        const currentPlaylistIndex = playlistIds.indexOf(playlistId);
        let nextPlaylistId = playlistId;

        const tracks = playlists[playlistId]?.musics;
        const isLastTrack = index  >= (tracks.length - 1);

        let newTrackIndex = index;

        if (isLastTrack) {
            if (!isRepeat && !autoPlayAllPlaylists) {
                return;
            }
            if (tracks.length === 1) {
                replayCurrentSong();
                return;
            }
            if (autoPlayAllPlaylists) {
                nextPlaylistId = currentPlaylistIndex >= (playlistIds.length - 1)
                    ? playlistIds[0]
                    : playlistIds[currentPlaylistIndex + 1];
            } 
            if (isRepeat || autoPlayAllPlaylists) {
                newTrackIndex = 0;
            }
        } else {
            newTrackIndex = index + 1;
        }

        if (isShuffle) {
            // manipulate the index of next song using shuffled index
            newTrackIndex = shuffledIndicesRef.current[newTrackIndex];
        }

        const newMusicId = playlists[nextPlaylistId]?.musics[newTrackIndex]?.musicId;

        if (newMusicId) {
            eventTracker(MUSIC_NEXT, { musicId: currentTrack.musicId });
            navigate(`/${nextPlaylistId}/${newMusicId}`, { state: { scrollTop: false } });
        }
    };

    /**
     * support play through muliple playlists
     * play previous music
     * if reach begining of current playlist then play last music of previous playlist
     * if no more previous playlist, play 1st music of last playlist
     */
    const handlePrevious = () => {
        if (isRepeatSingle) {
            replayCurrentSong();
            return;
        }

        setPlaylistIds();
        const playlistIds = playlistIdsRef.current;
        const currentPlaylistIndex = playlistIds.indexOf(playlistId);
        let prevPlaylistId = playlistId;
        const tracks = playlists[prevPlaylistId].musics;
        const isFirstTrack = index === 0;

        if (isFirstTrack) {
            if (!isRepeat && !autoPlayAllPlaylists) {
                return;
            }
            if (tracks.length === 1) {
                replayCurrentSong();
                return;
            }
            if (autoPlayAllPlaylists) {
                prevPlaylistId = currentPlaylistIndex === 0
                    ? playlistIds[playlistIds.length - 1]
                    : playlistIds[currentPlaylistIndex - 1];
            }
        }

        let newTrackIndex = isFirstTrack
            ? tracks.length - 1
            : index - 1;

        if (isShuffle) {
            // manipulate the index of prev song using shuffled index
            newTrackIndex = shuffledIndicesRef.current[newTrackIndex];
        }

        const newMusicId = playlists[prevPlaylistId]?.musics[newTrackIndex]?.musicId;

        if (newMusicId) {
            eventTracker(MUSIC_PREVIOUS, { musicId: currentTrack.musicId });
            navigate(`/${prevPlaylistId}/${newMusicId}`, { state: { scrollTop: false } });
        }
    };

    const handleRepeat = () => {
        // toggle isRepeat
        setRepeatPlaylist(!isRepeat);
    };

    const handlesRepeatSingle = ()=>{
        setRepeatSingle(!isRepeatSingle);
    };

    const handleShuffle = ()=> {
        // toggle isShuffle
        const newIsShuffleState = !isShuffle;
        
        // create an array with shuffled indices from currentPlaylist
        // store it in shuffledIndicesRef since no need to re-render
        shuffledIndicesRef.current = newIsShuffleState
            ? shuffleIndices(currentPlaylist.musics)
            : [];

        setShufflePlaylist(newIsShuffleState);
    };

    const {
        cover = '',
        musicName = '',
        author = '',
        views = 0,
        src = '',
    } = currentTrack;

    const updateDuration = useCallback((newDuration: number) => {
        setDuration(newDuration);
    }, [src]);

    const classNames = `miniPlayer ${className}`.trim();

    if (srcStatus === LOADING) {
        return (
            <div className={classNames}>
                <LoadingSkeleton />
            </div>
        );
    }

    const currentPlaylistTitle = currentPlaylist?.title || '';

    return (
        <>
            <div 
                className={classNames}
                onClick={handleModalOpen}
                onKeyDown={handleModalOpen}
                role="button"
                tabIndex={0}
                hidden={isModalOpen}
            >
                <Container 
                    maxWidth="lg"
                >
                    <Grid container>
                        <Grid 
                            item
                            xs={8}
                            className="miniPlayer__col-details" 
                        >
                            <div 
                                className="miniPlayer__music-img" 
                                style={{ backgroundImage: `url(${cover})` }}
                            />
                            <div className="miniPlayer__description">
                                <h5 className="text-truncated">
                                    {musicName}
                                </h5>
                                <small className="text-truncated">
                                    {author}
                                </small>
                                <div className="miniPlayer__listened">
                                    <MusicNoteIcon />
                                    {abbreviateNumber(views)}
                                </div>
                            </div>
                        </Grid>
                        <Grid 
                            className="miniPlayer__controls"
                            item
                            xs={4}
                        >
                            {/*
                            // @ts-ignore */}
                            <LikeButton className="miniPlayer__like" />
                            <ShareButton className="miniPlayer__icon-share" />
                            <MusicPlayerControls 
                                audioRef={audioRef}
                                showFullControls={false}
                                showVolumn={false}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <ProgressBar progressBarRef={progressBarRef} />
                <AudioControl {...{
                    audioSrc: src,
                    duration,
                    isPlaying,
                    handleNext,
                    progressBarRef,
                    progressInputRangeRef,
                    progressTimeRef,
                    audioRef,
                    updateDuration
                }}/>
            </div>
            <MusicDetailDialog
                className="music-details__dialog"
                containerProps={{ style: { backgroundImage: `url(${cover})` } }}
                open={isModalOpen}
                onClose={handleModalClose}
                currentTrack={currentTrack}
                fullScreen
            >
                <MusicDetails
                    {...{
                        playlistTitle: currentPlaylistTitle,
                        currentTrack,
                        duration,
                        audioRef,
                        progressInputRangeRef,
                        progressTimeRef,
                        handleNext,
                        handlePrevious,
                        handleRepeat,
                        handlesRepeatSingle,
                        handleShuffle,
                        autoPlayAllPlaylists
                    }}
                />
            </MusicDetailDialog>
        </>
    );
}

export default MiniPlayer;
