/* eslint-disable dot-notation */
import { initializeApp } from "firebase/app";
import {
    getAuth,
    setPersistence,
    browserSessionPersistence,
    // browserLocalPersistence,
    signInWithPopup, 
    GoogleAuthProvider,
    signOut,
} from "firebase/auth";
import { 
    getAnalytics,
    logEvent,
} from "firebase/analytics";
import { 
    getStorage,
    getDownloadURL,
    ref
} from "firebase/storage";
import { ID_PROVIDERS } from 'constants/index';
import firebaseConfig from 'configs/firebase';
import { IPlanObject } from "interfaces";
/*
import {
    safeJsonParse, 
    safeJsonStringify 
} from "utils";
 */

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAnalytics = getAnalytics(firebaseApp);


// -----------   firebase authentication  -------------------- //
// firebase aut reference
export const firebaseAuth = getAuth(firebaseApp);
// Google Auth provider setup
const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt : "select_account " });

export const signInWithProvider = (provider = '') => {
    const providerMap = {
        [ID_PROVIDERS.GOOGLE]: googleAuthProvider,
        [ID_PROVIDERS.FACEBOOK]: '',
    } as IPlanObject;

    const idProvider: any = providerMap[provider];

    if (provider) {
        return setPersistence(firebaseAuth, browserSessionPersistence)
            .then(()=>signInWithPopup(firebaseAuth, idProvider))
            // eslint-disable-next-line no-unused-vars
            .catch((err: any)=>{
                // console.log('signInWithProvider error: ', err);
                throw err;
            });
        
    }

    return Promise.reject(new Error(`invalid ID Provider: ${provider}`));
};

// eslint-disable-next-line arrow-body-style
export const getIdToken = async() => {
    return firebaseAuth.currentUser 
        ? firebaseAuth.currentUser.getIdToken()
        : Promise.resolve('');
};

export const signOutAsync = () => signOut(firebaseAuth);


// -----------   firebase Storage  -------------------- //
// Initialize Cloud Storage and get a reference to the service
export const firebaseStorage = getStorage(firebaseApp);

// Create a storage reference from our storage service
export const firebaseStorageRef = ref(firebaseStorage);

export const localStorageMaxAge = 10 * 60 * 60 * 1000; // 10 days;

// helper for file path conversion
export async function getSongData(data = {} as IPlanObject) {
    try {
        const {
            src = '',
            lyrics = '',
            cover = '',
            // eslint-disable-next-line no-unused-vars
            musicId = '',
        } = data;

        /* TODO - investigate best way to cahce this
        // get offline cache
        if (!window.navigator.onLine) {

            const musicCache = window.localStorage.getItem(musicId);
            if (musicCache) {
                const musicCacheData = safeJsonParse(musicCache) || {};

                if(musicCacheData.expireTime <= Date.now()){
                    // TODO - check what's going on. Song seems play 1 second and stopped
                    window.localStorage.removeItem(musicId);
                    throw Error('cache expired');
                }

                return {
                    src: musicCacheData.src || '',
                    lyrics: musicCacheData.lyrics || '',
                    cover: musicCacheData.cover || '',
                };
            }
        }
        */

        const srcPromise = getDownloadURL(ref(firebaseStorage, src));
        const lyricsPromise = getDownloadURL(ref(firebaseStorage, lyrics));
        const coverPromise = (/^https:\/\/storage.googleapis.com/).test(cover) || (/^.+\/default_cover/).test(cover)
            ? Promise.resolve(cover)
            : getDownloadURL(ref(firebaseStorage, cover));
    
        const [
            srcResult,
            lyricsResult,
            coverResult,
        ] = await Promise.allSettled([
            srcPromise,
            lyricsPromise,
            coverPromise
        ]);

        const ret = {
            src: srcResult.status === 'fulfilled' ? srcResult.value : '',
            lyrics: lyricsResult.status === 'fulfilled' ? lyricsResult.value : '',
            cover: coverResult.status === 'fulfilled' ? coverResult.value : ''
        };

        /*
        // save into localstorage for offline cache
        window.localStorage.setItem(musicId, safeJsonStringify({
            ...ret,
            expireTime: Date.now() + localStorageMaxAge
        }));
        */

        return ret;
    } catch(err) {
        throw err;
    }
}

export async function getFileUrl(gsPath = '') {
    return getDownloadURL(ref(firebaseStorage, gsPath));
}

// -----------   firebase Analytics  -------------------- //
// eslint-disable-next-line default-param-last
export const trackEvent = (eventName: string = '', opt?: IPlanObject | null | undefined) => {
    if (eventName) {
        return opt
            ? logEvent(firebaseAnalytics, eventName, opt)
            : logEvent(firebaseAnalytics, eventName);
    }
    return undefined;
};
